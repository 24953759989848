<template>
  <v-container fluid>
    <v-app-bar class="nav-bar" app color="rgba(185,18,118,.8)" elevation="12">
      <img src="imgs/gobinetlogo.png" width="50px" />

      <!--menu mdAndUp-->
      <v-tabs style="margin-left:70px;" v-if="$vuetify.breakpoint.mdAndUp">
        <v-tab><a href="#acerca" style="color:#FFFFFF; text-decoration: none;">Acerca de</a></v-tab>
        <v-tab><a href="#porque" style="color:#FFFFFF; text-decoration: none;">¿Por qué usarlo?</a></v-tab>
        <v-tab><a href="#como" style="color:#FFFFFF; text-decoration: none;">¿Cómo lo hacemos?</a></v-tab>
        <v-tab><a href="https://siprem.com.mx/index.html" style="color:#FFFFFF; text-decoration: none;">Medios de pago</a></v-tab>
        <v-tab><a href="#contacto" style="color:#FFFFFF; text-decoration: none;">Contacto</a></v-tab>
      </v-tabs>
      <v-spacer></v-spacer>

      <!--menu smAndDown-->
      <v-menu offset-y v-if="$vuetify.breakpoint.smAndDown">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="#3B004D" style="opacity: 100;" dark v-bind="attrs" v-on="on"> Menu </v-btn>
        </template>
        <v-list>
          <v-list-item href="#acerca"> Acerca de </v-list-item>
          <v-list-item href="#porque"> ¿Por qué usarlo? </v-list-item>
          <v-list-item href="#como"> ¿Cómo lo hacemos? </v-list-item>
          <v-list-item href="https://siprem.com.mx/index.html"> Medios de pago </v-list-item>
          <v-list-item href="#contacto"> Contacto </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!--paralax (only != ios)-->
    <v-row class="without-margin">
      <v-col>
        <!--mdAndUp -->
        <div class="parallax" v-if="$vuetify.breakpoint.mdAndUp && !isIos"><br/>
          <div class="title-page" >
            <p class="title-tramites">Trámites</p>
            <p class="title-en-linea">En línea</p>
            <p class="title-pago" id="acerca">y pago digital</p>
          </div>
        </div>
        <!--smAndDown-->
        <div class="parallax-sm" v-if="$vuetify.breakpoint.smAndDown && !isIos"><br/>
          <div class="title-page-sm">
            <p class="title-tramites-sm">Trámites</p>
            <p class="title-en-linea-sm">En línea</p>
            <p class="title-pago-sm" id="acerca">y pago digital</p>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- only == ios-->
    <v-row class="without-margin">
      <v-col>
        <!--mdAndUp -->
        <div class="img-ios" v-if="$vuetify.breakpoint.mdAndUp && isIos"><br/>
          <div class="title-page" >
            <p class="title-tramites">Trámites</p>
            <p class="title-en-linea">En línea</p>
            <p class="title-pago" id="acerca">y pago digital</p>
          </div>
        </div>
        <!--smAndDown-->
        <div class="img-ios-sm" v-if="$vuetify.breakpoint.smAndDown && isIos"><br/>
          <div class="title-page-sm-ios">
            <p class="title-tramites-sm">Trámites</p>
            <p class="title-en-linea-sm">En línea</p>
            <p class="title-pago-sm" id="acerca">y pago digital</p>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-------------------------------------------------------Gobinet-------------------------------------------------->
    
    <v-row :class="contentMarginSm">
      <v-col align="center" justify="center">
        <strong class="title-gobi">Gobi</strong><strong class="title-net">Net</strong><span class="text-content"> es la nueva plataforma que permite cumplir las expectativas y exigencias de la ciudadanía 
          en materia de servicios públicos, ya que habilita la recepción de trámites y pagos en línea, 
          disminuyendo costos de opercaión y elevando la calidad de vida de la gente.</span>
      </v-col>
    </v-row>
    <v-row :class="contentMarginSm">
      <v-col align="center" justify="center">
        <img src="imgs/womanphone.png" width="70%" />
      </v-col>
    </v-row>
    <!-------------------------------------------------¿Por qué usarlo?-------------------------------------------------->
    <div id="porque"></div>
    <v-row :class="contentMarginMd">
      <v-col cols="12" align="center" justify="center">
        <strong class="subtitle">¿Por qué usarlo?</strong><br>
        <p class="text-center">Genera un medio de comunicación óptimo con la ciudadanía creando un ambiente de aceptación y certeza hacia la administración pública, al mismo tiempo que elimina
			procesos burocráticos y eleva la productividad laboral dentro de cada institución.
        </p>
      </v-col>
    </v-row>
    <v-row :class="contentMarginSm" >
      <v-col md="6" cols="12" v-intersect="{ handler: onShowBeneficio1, options: { threshold: 1.0} }">
        <transition name="fade">
          <v-card shaped elevation="5" class="margin-card-flex" v-if="isShowBeneficio1">
            <v-row>
              <v-col xl="2" lg="3" md="12" cols="12" align="center">
                <img src="imgs/flexible.png" width="90px;" class="img-card"/>
              </v-col>
              <v-col xl="10" lg="9" md="12" cols="12" align="center" v-if="$vuetify.breakpoint.smAndDown">
                <b class="text-blue">Flexible</b><br>
                <p class="text-cards margin-text-cards">Desarrollado para una rápida y fácil incorporación al ambiente de trabajo actual de cada administración pública.</p>
              </v-col>
              <v-col xl="10" lg="9" md="12" cols="12" v-if="$vuetify.breakpoint.mdAndUp">
                <b class="text-blue">Flexible</b><br>
                <span class="text-cards">Desarrollado para una rápida y fácil incorporación al ambiente de trabajo actual de cada administración pública.</span>
              </v-col>
            </v-row>
          </v-card>
        </transition>
      </v-col>
      <v-col md="6" cols="12" v-intersect="{ handler: onShowBeneficio2, options: { threshold: 1.0} }">
        <transition name="fade">
          <v-card shaped elevation="5" v-if="isShowBeneficio2">
            <v-row>
              <v-col xl="2" lg="3" md="12" cols="12" align="center">
                <img src="imgs/costo.png" width="90px;" class="img-card"/>
              </v-col>
              <v-col xl="10" lg="9" md="12" cols="12" align="center" v-if="$vuetify.breakpoint.smAndDown">
                <b class="text-costo">Sin Costo</b><br>
                <p class="text-cards margin-text-cards">Somos una iniciativa cuyo objetivo es promover el Gobierno Digital de forma simple y sin grandes inversiones económicas.</p>
              </v-col>
              <v-col xl="10" lg="9" md="12" cols="12" v-if="$vuetify.breakpoint.mdAndUp">
                <b class="text-costo">Sin Costo</b><br>
                <span class="text-cards">Somos una iniciativa cuyo objetivo es promover el Gobierno Digital de forma simple y sin grandes inversiones económicas.</span>
              </v-col>
            </v-row>
          </v-card>
        </transition>
      </v-col>
    </v-row>
  <!-------------------------------------------------¿Cómo lo hacemos?-------------------------------------------------->
  <div id="como"></div>
    <v-row :class="contentMarginMd" >
      <v-col align="center" justify="center">
        <strong class="subtitle">¿Cómo lo hacemos?</strong><br>
        <p class="text-center">En tan solo 4 pasos habrás ofrecido un servicio de calidad, cumpliendo en materia de mejora regulatoria y creando nuevas experiencias en la ciudadanía.
        </p>
      </v-col>
    </v-row>
    <v-row :class="contentMarginSm">
      <v-col>
        <v-row justify="center">
          <v-col lg="7">
            <div class="div-intersect" v-intersect="{ handler: onShowPaso1, options: { threshold: 1.0} }">
              <transition name="fade">
                <v-card shaped elevation="10" class="margin-card-flex" v-if="isShowPaso1">
                  <v-row>
                    <v-col xl="2" lg="3" md="3" cols="12" class="d-flex justify-center">
                      <img src="imgs/reqs.png" width="95px;" height="113px;"/>
                    </v-col>
                    <v-col xl="8" lg="7" md="7" cols="12" align="center" v-if="$vuetify.breakpoint.smAndDown">
                      <b class="text-blue">Envío de Requisitos</b><br>
                      <p class="text-cards margin-text-cards">Cada contribuyente envía de forma digital los documentos solicitados desde cualquier dispositivo.</p>
                    </v-col>
                    <v-col xl="8" lg="7" md="7" cols="12" v-if="$vuetify.breakpoint.mdAndUp">
                      <b class="text-blue">Envío de Requisitos</b><br>
                      <span class="text-cards">Cada contribuyente envía de forma digital los documentos solicitados desde cualquier dispositivo.</span>
                    </v-col>
                    <v-col xl="2" lg="2" md="2" cols="12" align="center">
                      <v-avatar class="avatars" size="48" style="margin-top:20px;">
                        <span class="white--text headline">1</span>
                      </v-avatar>
                    </v-col>
                  </v-row>
                </v-card>
              </transition>
            </div>

            <div class="div-intersect" v-intersect="{ handler: onShowPaso2, options: { threshold: 1.0} }">
              <transition name="fade">
                <v-card shaped elevation="10" class="margin-card-flex" v-if="isShowPaso2">
                  <v-row>
                    <v-col xl="2" lg="3" md="3" cols="12" class="d-flex justify-center">
                      <img src="imgs/validation.png" width="110px;" height="104px;"/>
                    </v-col>
                    <v-col xl="8" lg="7" md="7" cols="12" align="center" v-if="$vuetify.breakpoint.smAndDown">
                      <b class="text-blue">Proceso de Validación</b><br>
                      <p class="text-cards margin-text-cards">La dependencia recibe los documentos enviados por el contribuyente para su revisión.</p>
                    </v-col>
                    <v-col xl="8" lg="7" md="7" cols="12" v-if="$vuetify.breakpoint.mdAndUp">
                      <b class="text-blue">Proceso de Validación</b><br>
                      <span class="text-cards">La dependencia recibe los documentos enviados por el contribuyente para su revisión.</span>
                    </v-col>
                    <v-col xl="2" lg="2" md="2" cols="12" align="center">
                      <v-avatar class="avatars" size="48" style="margin-top:20px;">
                        <span class="white--text headline">2</span>
                      </v-avatar>
                    </v-col>
                  </v-row>
                </v-card>
              </transition>
            </div>

            <div class="div-intersect" v-intersect="{ handler: onShowPaso3, options: { threshold: 1.0} }">
              <transition name="fade">
                <v-card shaped elevation="10" class="margin-card-flex" v-if="isShowPaso3">
                  <v-row>
                    <v-col xl="2" lg="3" md="3" cols="12" class="d-flex justify-center">
                      <img src="imgs/pay.png" width="113px;" height="105px;"/>
                    </v-col>
                    <v-col xl="8" lg="7" md="7" cols="12" align="center" v-if="$vuetify.breakpoint.smAndDown">
                      <b class="text-blue">Recepción del Pago</b><br>
                      <p class="text-cards margin-text-cards">El cobro se realiza en línea con cargo a tarjeta de crédito, tiendas oxxo, bancos, kioscos, o cajeros automáticos.</p><br>
                      <v-btn rounded color="#00244D" small href="https://siprem.com.mx/index.html" dark>Ver medios de pago</v-btn>
                    </v-col>
                    <v-col xl="8" lg="7" md="7" cols="12" v-if="$vuetify.breakpoint.mdAndUp">
                      <b class="text-blue">Recepción del Pago</b><br>
                      <span class="text-cards">El cobro se realiza en línea con cargo a tarjeta de crédito, tiendas oxxo, bancos, kioscos, o cajeros automáticos.</span><br>
                      <v-btn rounded color="#00244D" small href="https://siprem.com.mx/index.html" dark>Ver medios de pago</v-btn>
                    </v-col>
                    <v-col xl="2" lg="2" md="2" cols="12" align="center">
                      <v-avatar class="avatars" size="48" style="margin-top:20px;">
                        <span class="white--text headline">3</span>
                      </v-avatar>
                    </v-col>
                  </v-row>
                </v-card>
              </transition>
            </div>

            <div class="div-intersect" v-intersect="{ handler: onShowPaso4, options: { threshold: 1.0} }">
              <transition name="fade">
                <v-card shaped elevation="10" class="margin-card-flex" v-if="isShowPaso4">
                  <v-row>
                    <v-col xl="2" lg="3" md="3" cols="12" class="d-flex justify-center">
                      <img src="imgs/folder.png" width="100px;" height="105px;"/>
                    </v-col>
                    <v-col xl="8" lg="7" md="7" cols="12" align="center" v-if="$vuetify.breakpoint.smAndDown">
                      <b class="text-blue">Entrega de Documentos</b><br>
                      <p class="text-cards margin-text-cards">Se fija día y hora de entrega de los documentos caso contrario se envian de forma electrónica al contribuyente.</p>
                    </v-col>
                    <v-col xl="8" lg="7" md="7" cols="12" v-if="$vuetify.breakpoint.mdAndUp">
                      <b class="text-blue">Entrega de Documentos</b><br>
                      <span class="text-cards">Se fija día y hora de entrega de los documentos caso contrario se envian de forma electrónica al contribuyente.</span>
                    </v-col>
                    <v-col xl="2" lg="2" md="2" cols="12" align="center">
                      <v-avatar class="avatars" size="48" style="margin-top:20px;">
                        <span class="white--text headline">4</span>
                      </v-avatar>
                    </v-col>
                  </v-row>
                </v-card>
              </transition>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-------------------------------------------------------------------------Contacto----------------------------------------------------------------->
    <div id="contacto"></div>
    <v-row :class="contentMarginMd">
      <v-col align="center" justify="center">
        <img src="imgs/qts.png" width="50px;"/><br>
        <span style="font-size:30px;">Implantar un Gobierno Digital es forjar el camino de las administraciones públicas hacia una sana convivencia con la ciudadanía</span>
      </v-col>
    </v-row>
     <v-row :class="contentMarginSm">
      <v-col align="center" justify="center">
        <strong class="subtitle">Contacto</strong>
      </v-col>
    </v-row>
     <v-row :class="contentMarginSm">
      <v-col md="6" cols="12" align="end" justify="end" v-if="$vuetify.breakpoint.mdAndUp">
        <img src="imgs/angel.png" width="300px;"/>
      </v-col>
      <v-col md="6" cols="12" align="center" justify="center" v-if="$vuetify.breakpoint.smAndDown">
        <img src="imgs/angel.png" width="300px;"/>
      </v-col>
      <v-col md="6" cols="12" v-if="$vuetify.breakpoint.mdAndUp">
        <p style="margin-top:95px;"><v-avatar color="pink darken-2" size="35"><v-icon dark>mdi-phone</v-icon></v-avatar><span style="margin-left:5px;">55.30.80.26.44 (Whatsapp)</span></p>
        <p><v-avatar color="pink darken-2" size="35"><v-icon dark>mdi-at</v-icon></v-avatar><span style="margin-left:5px;">info@siprem.com.mx</span></p>
        <p><v-avatar color="pink darken-2" size="35"><v-icon dark>mdi-map-marker-radius</v-icon></v-avatar><span style="margin-left:5px;">www.siprem.com.mx</span></p>
      </v-col>
      <v-col md="6" cols="12" v-if="$vuetify.breakpoint.smAndDown" align="center" justify="center">
        <p><v-avatar color="pink darken-2" size="35"><v-icon dark>mdi-phone</v-icon></v-avatar><span style="margin-left:5px;">729 964 5819 (Whatsapp)</span></p>
        <p><v-avatar color="pink darken-2" size="35"><v-icon dark>mdi-at</v-icon></v-avatar><span style="margin-left:5px;">info@siprem.com.mx</span></p>
        <p><v-avatar color="pink darken-2" size="35"><v-icon dark>mdi-map-marker-radius</v-icon></v-avatar><span style="margin-left:5px;">www.siprem.com.mx</span></p>
      </v-col>
    </v-row><br><br><br><br>
    <v-row justify="center"><span style="color:#CE0294">© 2023 Reew Technologies S.A.S. de C.V.</span></v-row>
  </v-container>
</template>

<script>
  export default {
    computed: {
      contentMarginMd: function(){
        var contentMarginMd = null;
        if(this.$vuetify.breakpoint.xlOnly){
          contentMarginMd = "content-mg-md-mdAndUp";
        }else if(this.$vuetify.breakpoint.lgOnly){
          contentMarginMd = "content--mg-md-smAndDown";
        }
        return contentMarginMd;
      },
      contentMarginSm: function() {
        var contentMarginSm = null;
        if(this.$vuetify.breakpoint.xlOnly){
          contentMarginSm = "content-mg-sm-mdAndUp";
        }else if(this.$vuetify.breakpoint.lgOnly){
          contentMarginSm = "content--mg-sm-smAndDown";
        }
        return contentMarginSm;
      }
    },
    data: () => ({
      isShowBeneficio1:false,
      isShowBeneficio2:false,
      isShowPaso1:false,
      isShowPaso2:false,
      isShowPaso3:false,
      isShowPaso4:false,
      screenW:window.innerWidth,
      screenH:window.innerHeight,
      isIos:false
    }),
    created() {
      this.isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },
    methods:{
      onShowBeneficio1(entries){
        if(this.isShowBeneficio1 == false){
          this.isShowBeneficio1 = entries[0].isIntersecting;
        }
      },
      onShowBeneficio2(entries){
        if(this.isShowBeneficio2 == false){
          this.isShowBeneficio2 = entries[0].isIntersecting;
        }
      },
      onShowPaso1(entries){
        if(this.isShowPaso1 == false){
          this.isShowPaso1 = entries[0].isIntersecting;
        }
      },
      onShowPaso2(entries){
        if(this.isShowPaso2 == false){
          this.isShowPaso2 = entries[0].isIntersecting;
        }
      },
      onShowPaso3(entries){
        if(this.isShowPaso3 == false){
          this.isShowPaso3 = entries[0].isIntersecting;
        }
      },
      onShowPaso4(entries){
        if(this.isShowPaso4 == false){
          this.isShowPaso4 = entries[0].isIntersecting;
        }
      }
    }
  }
</script>
<style scoped>
:root {
  --main-title-color: #CE0294;
  --subtitle-size: 50px;
  --text-size:25px;
  --text-cards:18px;
}
.margin-text-cards{
  margin-left:20px; 
  margin-right:20px;
}
.avatars{
  background-color:#CE0294;
}
.div-intersect{
  min-height:150px;
}
.img-card{
  margin-left:15px;
}
.text-cards{
  font-size: var(--text-cards);
}
.text-content{
  font-size: var(--text-size);
}
div.without-margin{
  margin-top:-115px;
  margin-left:-24px; 
  margin-right:-24px;
  margin-bottom:70px;
}
.title-page{
  margin-top:450px; 
  margin-left: 40px;
}
.title-page-sm{
  margin-top:320px; 
  margin-left: 10px;
}
.title-page-sm-ios{
  margin-top:400px; 
  margin-left: 10px;
}
.title-tramites{
  font-size: 85px;
  color:#00244D;
  font-weight: bold;
}
.title-en-linea{
  font-size: 130px;
  color:var(--main-title-color);
  font-weight: bold;
  margin-top:-60px;
}
.title-pago{
  color:#000000;
  font-size: 65px;
  margin-left:10px;
  margin-top:-60px;
}
.title-tramites-sm{
  font-size: 45px;
  color:#00244D;
  font-weight: bold;
}
.title-en-linea-sm{
  font-size: 70px;
  color:var(--main-title-color);
  font-weight: bold;
  margin-top:-50px;
}
.title-pago-sm{
  font-size: 45px;
  margin-left:10px;
  margin-top:-55px;
}
.div.en-linea{
  height:300px;
  background-color:#FFFFFF;
}
.margin-bottom-row-card{
  margin-bottom:-10px;
}
.text-blue{
  font-size: 20px;
  color:#00244D;
}
.margin-card-flex{
  margin-bottom:25px;
}
.text-costo{
  font-size: 20px;
  color:var(--main-title-color);
}
p.text-justify{
  text-align: justify;
}
p.text-center{
  font-size: var(--text-size);
  text-align: center;
}
.subtitle{
  font-size: var(--subtitle-size);
  color:var(--main-title-color);
}
.title-gobi{
  font-size: 27px; 
  color:var(--main-title-color);
}
.title-net{
  font-size: 27px; 
  color:#00244D;
}
.title-appbar{
  color:#FFFFFF; 
  margin-left:15px;
}
div.content-mg-sm-mdAndUp{
  margin-left:200px;
  margin-right:200px;
}
div.content--mg-sm-smAndDown{
  margin-left:0px;
  margin-right:0px;
}
div.content-mg-md-mdAndUp{
  margin-top:150px;
  margin-left:200px; 
  margin-right:200px;
}
div.content--mg-md-smAndDown{
  margin-top:150px;
  margin-left:0px; 
  margin-right:0px;
}
div.content{
  margin-top:60px;
  margin-bottom:70px;
  margin-left:200px; 
  margin-right:200px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1.0s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Parallax */
.parallax {
  /* The image used */
  background-image: url("/imgs/ENLINEA.jpg");

  /* Set a specific height */
  height: 850px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax-sm {
  /* The image used */
  background-image: url("/imgs/ENLINEA.jpg");

  /* Set a specific height */
  height: 600px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.img-ios{
  background-image: url("/imgs/ENLINEA.jpg");
  height: 850px;
  background-size: cover;
}
.img-ios-sm{
  background-image: url("/imgs/ENLINEA.jpg");
  height: 630px;
  background-position: center;
  background-size: cover;
}
</style>
